import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosClient from "api/api";

interface ExamResultData {
  course_id: string;
  chapter_id: number;
  sort_column?: string | null;
  exam_id?: number | null;
  sort_direction?: string | null;
}
interface classManagementQuizStatus {
  course_id:string;
}
interface classManagementQuizWiseStatus {
  quiz_id:string | undefined;
  course_id:string;
}
interface studentsExamDetails {
  course_id:string;
  exam_id:string | undefined;
}
interface teacherDashboardData {
  teacher_id:string | null;
  course_id:string;
}

export const GetClassManagementExamResult = createAsyncThunk(
  "classManagementSlice/examResult",
  async (data: ExamResultData) => {
    const { course_id, chapter_id, sort_column, exam_id, sort_direction } =
      data;

    const queryParams = new URLSearchParams({
      course_id,
      chapter_id: chapter_id.toString(),
      ...(exam_id && { exam_id: exam_id.toString() }),
      ...(sort_column !== null && { sort_column }),
      ...(sort_direction !== null && { sort_direction }),
    });
    try {
      const res = await axiosClient.get(
        `get-students-exam-result?${queryParams}`
      );
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const GetClassManagementQuizStatus = createAsyncThunk(
  "classManagementSlice/quizStatus",
  async (data: classManagementQuizStatus) => {
    try {
      const res = await axiosClient.get(
        `get-quiz-status?course_id=${data.course_id}`
      );
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);
export const GetClassManagementQuizWiseResult = createAsyncThunk(
  "classManagementSlice/quizWiseResult",
  async (data: classManagementQuizWiseStatus) => {
    try {
      const res = await axiosClient.get(
        `get-class-quiz-result?quiz_id=${data.quiz_id}&course_id=${data.course_id}`
      );
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const FetchStudentsExamDetails = createAsyncThunk(
  "classManagementSlice/classManagementStudentsExamDetails",
  async (data: studentsExamDetails) => {
    try {
      const res = await axiosClient.get(
        `get-students-exam-details?course_id=${data.course_id}&exam_id=${data.exam_id}`
      );
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const FetchTeacherDashboardData = createAsyncThunk(
  "classManagementSlice/classManagementTeacherDashboardData",
  async (data: teacherDashboardData) => {
    try {
      const res = await axiosClient.get(
        `get-teacher-dashboard-data?teacher_id=${data.teacher_id}&course_id=${data.course_id}`
      );
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

interface initialStage {
  isLoading: boolean;
  error: any;
  average:any;
  getClassManagementExamResultData: any[];
  studentsExamDetails: any[];
  teacherDashboardData: any[];
  getClassManagementQuizTestData: {
    data: [
      [
        {
          quiz_name: string;
          quiz_id: number;
        }
      ],
      [
        {
          user_name: string;
          user_id: string;
          quiz_data: [
            {
              quiz_id: number;
              average_score: string;
            }
          ];
        }
      ]
    ];
    current_page: number;
    per_page: number;
    total: number;
    to: number;
  };
  quizWiseResult: {
    data: [
      {
        question_text: string;
        question_tags: any[];
        success_rate: string;
        options: [
          {
            is_correct: number;
            percentage: string;
          }
        ];
        is_correct_option: number;
      }
    ];
    subject_inspection: [
      {
        count: number;
        name: string;
        percentage: number;
      }
    ];
  };
}

const initialState: initialStage = {
  isLoading: false,
  error: null,
  average:{},
  getClassManagementExamResultData: [],
  studentsExamDetails: [],
  teacherDashboardData: [],
  getClassManagementQuizTestData: {
    data: [
      [
        {
          quiz_name: "",
          quiz_id: 0,
        },
      ],
      [
        {
          user_name: "",
          user_id: "",
          quiz_data: [
            {
              quiz_id: 0,
              average_score: "",
            },
          ],
        },
      ],
    ],
    current_page: 0,
    per_page: 0,
    total: 0,
    to: 0,
  },
  quizWiseResult: {
    data: [
      {
        question_text: "",
        question_tags: [],
        success_rate: "",
        options: [
          {
            is_correct: 0,
            percentage: "",
          },
        ],
        is_correct_option: 0,
      },
    ],
    subject_inspection: [
      {
        count: 0,
        name: "",
        percentage: 0,
      },
    ],
  },
};

const ClassManagementSlice = createSlice({
  name: "studentManagementExamResultSlice",
  initialState,
  reducers: {
    updateSelectedChapter: (state, action) => {
      return { ...state, selectedChapter: action.payload };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(GetClassManagementExamResult.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(GetClassManagementExamResult.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          getClassManagementExamResultData: action.payload.data,
        };
      })
      .addCase(GetClassManagementExamResult.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(GetClassManagementQuizStatus.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(GetClassManagementQuizStatus.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          getClassManagementQuizTestData: action.payload.data,
        };
      })
      .addCase(GetClassManagementQuizStatus.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(FetchStudentsExamDetails.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
          average:{}
        };
      })
      .addCase(FetchStudentsExamDetails.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          studentsExamDetails: action.payload.data,
          average:action.payload.average_row
        };
      })
      .addCase(FetchStudentsExamDetails.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(FetchTeacherDashboardData.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(FetchTeacherDashboardData.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          teacherDashboardData: action.payload.data,
        };
      })
      .addCase(FetchTeacherDashboardData.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(GetClassManagementQuizWiseResult.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(GetClassManagementQuizWiseResult.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          quizWiseResult: action.payload,
        };
      })
      .addCase(GetClassManagementQuizWiseResult.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      });
  },
});

export const { updateSelectedChapter } = ClassManagementSlice.actions;

export default ClassManagementSlice.reducer;
