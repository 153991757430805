import { useEffect, useState } from "react";
import { Box } from "@mui/joy";
import { Alert, Tab, Tabs } from "@mui/material";
import {
  GetChapterExamQuestion,
  GetChapterPrincipleAccordion,
  setResultPage,
  setPrincipleAccordionNavigationFlag,
  updateSelectedChapter,
} from "Redux/feature/ChapterExamSlice";
import { useAppDispatch, useAppSelector } from "Redux/App/hooks";
import { FormattedMessage } from "react-intl";
import {
  FetchQuestions,
  GetAllSubmittedQuestion,
  setNumericBar,
  setProgressBarFlag,
  setProgressBarNavigation,
  setQuizQuestionComplete,
  setResearchTab,
  updateActiveQuestion,
} from "Redux/feature/Questions";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import plushIcon from "../../assets/images/plushIconAccordian.svg";
import minusIcon from "../../assets/images/minusIconAccordian.svg";
import { getCookie } from "./cookieUtils";

type Props = {
  assignmentId: any;
};

interface ChapterItem {
  id: number;
  chapter_name: string;
  number_of_questions: number;
  number_of_true_ans: number;
  chapterData: ChapterChildItem[];
}

interface ChapterChildItem {
  name: string;
  questions: any[];
  number_of_true_ans: number;
}

interface AccordionItem {
  chapter: ChapterItem;
}

const AccordionForChapterPrinciple = ({ assignmentId }: Props) => {
  const user_id = getCookie("id");
  const userType = getCookie("user_type");

  const studentId = getCookie("studentId_teacherSide");

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [containerWidth, setContainerWidth] = useState(window.innerWidth);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const handleResize = () => {
      setContainerWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (user_id) {
      dispatch(
        GetChapterPrincipleAccordion({
          user_id: userType === "Student" ? user_id : studentId,
          assignment_id: assignmentId,
          ...((containerId == "10" || containerId == "11") && { type: "Online Exam" }),
        })
      );
    }
  }, [dispatch, user_id]);

  const [currentTab, setCurrentTab] = useState(0);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);

  const containerId = searchParams.get("container_type_id");
  const ChapterPrincipleAccordion: any = useAppSelector(
    (state) => state?.ChapterExamSlice.getChapterPrincipleAccordion
  );

  const chapterWiseData = ChapterPrincipleAccordion?.chapterWiseData;
  const tagQuestionData = ChapterPrincipleAccordion?.tagQuestionData;

  const tagNames = tagQuestionData?.map((item: any) => item?.tag);

  const lastChildIds = chapterWiseData?.map((item: any) =>
    item.length > 0 ? item[item.length - 1].id : null
  );
  const [openTagAccordions, setOpenTagAccordions] = useState<boolean>(false);
  const [filterQuestionData, setFilterQuestionData] = useState<any>();
  const [errMess, setErrMess] = useState<any>();

  const [openAccordions, setOpenAccordions] = useState<number[]>(
    lastChildIds?.filter((id: any) => id !== null) as number[]
  );
  const location = useLocation();
  const assignment_id = searchParams.get("assignment_id");
  const assignment_type: number = useAppSelector(
    (state) => state.ChapterExamSlice?.assignment_type
  );
  useEffect(() => {
    if (tagQuestionData) {
      setFilterQuestionData(tagQuestionData[0]?.tag?.chapter);
    }
  }, [openTagAccordions]);

  const toggleAccordion = (parentId: number) => {
    const isParentOpen = openAccordions?.includes(parentId);
    if (isParentOpen) {
      setOpenAccordions(openAccordions?.filter((id) => id !== parentId));
    } else {
      if (!Array.isArray(openAccordions)) {
        setOpenAccordions([]);
      } else {
        setOpenAccordions([...openAccordions, parentId]);
      }
    }
  };

  const handleClick = (data: any, index: number) => {
    if (location.pathname == `/home/student-management/student-exam/result`) {
    } else {
      if (
        // location.pathname.startsWith(
        //   "/home/learning-management/research-test"
        // ) ||
        location.pathname.startsWith("/home/learning-management")
      )
        if (assignment_type == 4 || assignment_type == 5) {
          dispatch(
            FetchQuestions({
              user_id: user_id ? user_id : null,
              container_id: data?.container_id,
              assignment_id: assignmentId,
            })
          ).then((res) => {
            dispatch(
              GetAllSubmittedQuestion({
                user_id: user_id,
                assignment_id: assignmentId,
                container_id: data?.container_id,
              })
            ).then((resp) => {
              const container_id = resp.payload?.chapter.find(
                (i: any) => i.id === data.container_id
              )?.id;
              if (container_id) {
                dispatch(updateSelectedChapter(container_id));
              } else {
                dispatch(updateSelectedChapter(data.container_id));
              }
            });
            dispatch(setProgressBarNavigation(true));
            dispatch(setNumericBar(true));
            dispatch(setResearchTab(false));

            dispatch(setProgressBarFlag(true));
            dispatch(updateActiveQuestion(data?.question_number - 1));
            dispatch(setQuizQuestionComplete(true));
            let path;
            if (assignment_type == 4) {
              path = `/question-pages?container_type_id=10&container_id=${data?.container_id}&assignment_id=${assignmentId}&isResearch=true`;

            } else {


              path = `/question-pages?container_type_id=11&container_id=${data?.container_id}&assignment_id=${assignmentId}&isResearch=true`;
            }

            navigate(path, { state: { isResearch: true } });
          });
        } else {
          {
            dispatch(
              GetChapterExamQuestion({
                user_id: user_id ? user_id : null,
                assignment_id: data.answer.exam_id,
              })
            ).then((res: any) => {
              dispatch(
                GetAllSubmittedQuestion({
                  user_id: user_id,
                  assignment_id: res?.payload?.data?.assignment[0]?.id,
                  container_id: data.container_id,
                })
              ).then((response: any) => {
                if (!response.payload) {
                  setShowAlert(true);
                  setShowErrorMessage(true);
                  setErrMess(response.error);
                } else {
                  const container_id = response.payload?.chapter.find(
                    (i: any) => i.id === data.container_id
                  )?.id;
                  if (container_id) {
                    dispatch(updateSelectedChapter(container_id));
                  } else {
                    dispatch(updateSelectedChapter(data.container_id));
                  }
                  dispatch(setResultPage(true));
                  dispatch(setProgressBarFlag(true));
                  dispatch(setNumericBar(true));
                  dispatch(updateActiveQuestion(data?.question_number - 1));
                  dispatch(setQuizQuestionComplete(true));
                  dispatch(setPrincipleAccordionNavigationFlag(false));

                  if (container_id) {
                    navigate(
                      `/question-pages?container_type_id=5&container_id=${container_id}&assignment_id=${res?.payload?.data?.assignment[0]?.id
                      }&isResearch=${true}`,
                      { state: { isResearch: true } }
                    );
                  } else {
                    navigate(
                      `/question-pages?container_type_id=5&container_id=${data.container_id
                      }&assignment_id=${res?.payload?.data?.assignment[0]?.id
                      }&isResearch=${true}`,
                      { state: { isResearch: true } }
                    );
                  }
                }
              });
            });
          }
        }
      else {
        if ((containerId == "10" || containerId == "11") && assignment_id) {
          dispatch(
            FetchQuestions({
              user_id: user_id ? user_id : null,
              container_id: data?.container_id,
              assignment_id: assignment_id,
            })
          ).then((res) => {
            dispatch(
              GetAllSubmittedQuestion({
                user_id: user_id,
                assignment_id: assignment_id,
                container_id: data?.container_id,
              })
            ).then((resp) => {
              const container_id = resp.payload?.chapter.find(
                (i: any) => i.id === data.container_id
              )?.id;
              if (container_id) {
                dispatch(updateSelectedChapter(container_id));
              } else {
                dispatch(updateSelectedChapter(data.container_id));
              }
            });

            dispatch(setProgressBarNavigation(true));
            dispatch(updateActiveQuestion(data?.question_number - 1));
            dispatch(setNumericBar(true));
            dispatch(setResearchTab(false));
            dispatch(setQuizQuestionComplete(true));
          });
        } else {
          dispatch(
            GetChapterExamQuestion({
              user_id: user_id ? user_id : null,
              assignment_id: assignmentId,
            })
          ).then((res: any) => {
            dispatch(
              GetAllSubmittedQuestion({
                user_id: user_id,
                assignment_id: res?.payload?.data?.assignment[0]?.id,
                container_id: data.container_id,
              })
            ).then((response: any) => {
              if (!response.payload) {
                setShowAlert(true);
                setShowErrorMessage(true);
                setErrMess(response.error);
              } else {
                dispatch(
                  updateSelectedChapter(
                    response.payload?.chapter.find(
                      (i: any) => i.id === data.container_id
                    )?.id
                  )
                );
                dispatch(setResultPage(true));
                dispatch(setProgressBarFlag(true));
                dispatch(setNumericBar(true));
                dispatch(updateActiveQuestion(index));
                dispatch(setQuizQuestionComplete(true));
                dispatch(setPrincipleAccordionNavigationFlag(false));
              }
            });
          });
        }
      }
    }
  };
  const isAccordionOpen = (parentId: number) => {
    return openAccordions?.includes(parentId);
  };
  const handleChange = (
    event: React.SyntheticEvent,
    newValue: number,
    tagId: number
  ) => {
    setCurrentTab(newValue);
    const tagData = tagQuestionData.filter(
      (item: any) => item.tag.tag_id === tagId
    );
    setFilterQuestionData(tagData[0]?.tag?.chapter);
  };
  const isTagAccordionOpen = () => {
    if (openTagAccordions) return true;
    else return false;
  };

  const toggleTagAccordion = () => {
    if (openTagAccordions) setOpenTagAccordions(false);
    else setOpenTagAccordions(true);
  };

  const colors = ["#F1F3F5", "#E8EBED", "#F8F9FA"];
  const url = location.pathname.startsWith(
    "/home/learning-management/research-test/"
  );
  const renderAccordion = (item: AccordionItem, level: number) => {
    const isParentOpen = isAccordionOpen(item.chapter.id);
    const parentColor = colors[item.chapter.id % colors.length];
    const isToggleDisabled = item?.chapter?.chapterData?.length === undefined;

    return (
      <Box>
        <Box
          onClick={() => !isToggleDisabled && toggleAccordion(item.chapter.id)}
          sx={{
            cursor: isToggleDisabled ? "not-allowed" : "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "10px",
            paddingLeft: "35px",
            paddingRight: { xs: "25px", md: "35px" },
            borderBottom: "1px solid #DFE2E5",
            borderTop: "0",
            borderLeft: "0",
            borderRight: "0",
            backgroundColor: isParentOpen
              ? parentColor
              : isToggleDisabled
                ? "#dadfe2 "
                : "#FFF",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
            <div
              style={{
                color: "#7A49B9",
                background: "#FFFFFF",
                width: "35px",
                height: "35px",
                borderRadius: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                verticalAlign: "center",
                boxShadow: "0px 5px 12px rgba(8, 15, 52, 0.1)",
              }}
            >
              {isToggleDisabled ? (
                <img
                  src={plushIcon}
                  alt="Plus Icon"
                  loading="lazy"
                  title="Plus Icon"
                  style={{ width: "100%", height: "100%" }}
                />
              ) : isParentOpen ? (
                <img
                  src={minusIcon}
                  alt="Minus Icon"
                  loading="lazy"
                  title="Minus Icon"
                  style={{ width: "100%", height: "100%" }}
                />
              ) : (
                <img
                  src={plushIcon}
                  alt="Plus Icon"
                  loading="lazy"
                  title="Plus Icon"
                  style={{ width: "100%", height: "100%" }}
                />
              )}
            </div>
            <h3 style={{ margin: "0" }}> {item.chapter.chapter_name} </h3>
          </div>
          {/* <div>{item.parent.true_percentage}</div> */}
        </Box>
        {isParentOpen && (
          <div style={{}}>
            {item?.chapter?.chapterData?.length > 0 ? (
              <Box
                sx={{
                  backgroundColor: "#DFE2E5",
                  color: "#333333",
                  // padding: "5px 60px",
                  padding: { xs: "5px 25px", md: "5px 60px" },
                  paddingLeft: { xs: "5px" },
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <div>
                  {item?.chapter?.chapterData?.map(
                    (data: any, index: number) => (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          flexDirection: { xs: "column", md: "row" },
                          justifyContent: "center",
                          alignContent: "center",
                          verticalAlign: "center",
                          gap: "10px",
                          width: { md: "fit-content" },
                          padding: "5px",
                          paddingRight: { xs: "0", md: "15px" },
                        }}
                      >
                        <Box sx={{ display: "flex", gap: "5px" }}>
                          <Box
                            sx={{
                              fontSize: "16px",
                              width: { xs: "auto", md: "auto" },
                            }}
                          >
                            {data.name}
                          </Box>
                          <span>
                            ({data?.number_of_true_ans}/
                            {assignment_type == 4 || containerId == "10" || containerId == "11"
                              ? data?.number_of_questions
                              : item.chapter.number_of_questions}
                            )
                          </span>
                        </Box>
                        <Box
                          sx={{
                            display: { xs: "flex" },
                            overflow: { xs: "auto" },
                            flex: { xs: 1 },
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: { md: "wrap" },
                              width: { md: "auto" },
                              justifyContent: "flex-start",
                              gap: { xs: "6px", md: "8px" },
                            }}
                          >
                            {data.questions.map(
                              (questionData: any, questionIndex: number) => (
                                <Box
                                  onClick={() =>
                                    handleClick(questionData, questionIndex)
                                  }
                                  sx={{
                                    width: "22px",
                                    cursor: "pointer",
                                    height: "22px",
                                    borderRadius: "3px",
                                    backgroundColor: "white",
                                    color: ` ${questionData?.answer
                                      ?.is_skipped_question === 1 ||
                                      !questionData.answer
                                      ? "#AEB5BC"
                                      : questionData?.answer
                                        ?.is_correct_answer === 0
                                        ? "#E74C3C"
                                        : "#33CD75"
                                      } 
                                      `,

                                    border: "1px solid  #AEB5BC",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",

                                    gap: "10px",
                                    marginBottom: "6px",
                                    padding: "auto",
                                    fontSize: "14px",
                                    fontWeight: "700",
                                  }}
                                  key={questionIndex}
                                >
                                  {questionIndex + 1}
                                </Box>
                              )
                            )}
                          </Box>
                        </Box>
                      </Box>
                    )
                  )}
                </div>
              </Box>
            ) : (
              <></>
            )}
          </div>
        )}
      </Box>
    );
  };

  const renderTagAccordion = (item: any) => {
    const isOpen = isTagAccordionOpen();

    return (
      <Box>
        <div
          onClick={() => toggleTagAccordion()}
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "10px",
            paddingLeft: "35px",
            paddingRight: "35px",
            border: "1px solid #DFE2E5",
            borderTop: "0",
            borderLeft: "0",
            borderRight: "0",
            backgroundColor: isOpen ? "#F1F3F5" : "#FFF",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
            <div
              style={{
                color: "#7A49B9",
                background: "#FFFFFF",
                width: "35px",
                height: "35px",
                borderRadius: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                verticalAlign: "center",
                boxShadow: "0px 5px 12px rgba(8, 15, 52, 0.1)",
              }}
            >
              {isOpen ? (
                <img
                  src={minusIcon}
                  alt="Minus Icon"
                  loading="lazy"
                  title="Minus Icon"
                  style={{ width: "100%", height: "100%" }}
                />
              ) : (
                <img
                  src={plushIcon}
                  alt="Plus Icon"
                  loading="lazy"
                  title="Plus Icon"
                  style={{ width: "100%", height: "100%" }}
                />
              )}
            </div>
            <h3 style={{ margin: "0" }}>
              {" "}
              <FormattedMessage id="transverse-principles" />{" "}
            </h3>
          </div>
        </div>
        {isOpen && (
          <div style={{}}>
            {item ? (
              <div
                style={{
                  backgroundColor: "#DFE2E5",
                  color: "#333333",
                  padding: "5px 60px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  overflowX: "auto",
                }}
              >
                <Tabs
                  sx={{
                    "& .MuiTabs-indicator": {
                      backgroundColor: "#7A49B9",
                    },
                  }}
                  value={currentTab}
                  onChange={(event, newValue) =>
                    handleChange(event, newValue, tagNames[newValue]?.tag_id)
                  }
                  aria-label="disabled tabs example"
                  variant={"scrollable"}
                  scrollButtons={false}
                  visibleScrollbar
                >
                  {tagNames?.map((item: any, index: number) => (
                    <Tab
                      key={index}
                      // onClick={()=>handleTabClick(item?.tag_id)}
                      color="error"
                      label={item?.name}
                      sx={{
                        color: "#878E95",
                        "&.MuiTab-root.Mui-selected": {
                          color: "#7A49B9",
                          fontWeight: 600,
                        },
                        "&.MuiTab-root:not(.Mui-selected)": {
                          borderBottom: "1px solid #878E95",
                        },
                      }}
                    />
                  ))}
                </Tabs>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>
                    {filterQuestionData?.map((data: any, index: number) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignContent: "center",
                          verticalAlign: "center",
                          gap: "10px",
                          width: "fit-content",
                          padding: "5px",
                          paddingRight: "15px",
                        }}
                      >
                        <p style={{ fontSize: "16px", width: "auto" }}>
                          {data.name}
                        </p>
                        {/* <span>({item.chapter.number_of_questions})</span> */}
                        {/* <span>(11/20)</span> */}
                        <span>
                          {`(${data.questions.filter(
                            (ques: any) =>
                              ques?.answer?.is_correct_answer === 1
                          ).length
                            }/${data.questions.length})`}
                        </span>
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "auto",
                            justifyContent: "flex-start",
                            gap: "15px",
                          }}
                        >
                          {data.questions.map(
                            (questionData: any, questionIndex: number) => (
                              <div
                                onClick={() =>
                                  handleClick(questionData, questionIndex)
                                }
                                style={{
                                  width: "22px",
                                  cursor: "pointer",
                                  height: "22px",
                                  borderRadius: "5px",
                                  backgroundColor: "white",
                                  color: ` ${questionData?.answer
                                    ?.is_skipped_question === 1 ||
                                    !questionData.answer
                                    ? "#AEB5BC"
                                    : questionData?.answer
                                      ?.is_correct_answer === 0
                                      ? "#E74C3C"
                                      : "#33CD75"
                                    } 
                                      `,

                                  border: "1px solid  #AEB5BC",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  alignItems: "center",

                                  gap: "10px",
                                  marginBottom: "6px",
                                  padding: "auto",
                                  fontSize: "14px",
                                  fontWeight: "700",
                                }}
                                key={questionIndex}
                              >
                                {questionData.question_number}
                              </div>
                            )
                          )}
                        </Box>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        )}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "50px",
      }}
    >
      <Box
        sx={{
          borderRadius: "20px",
          border: url ? "1px solid  #DFE2E5" : "1px solid  transparent",
          boxShadow: "0px 5px 16px 0px rgba(8, 15, 52, 0.05)",
          overflow: "hidden",
        }}
      >
        {chapterWiseData?.map((item: any) => renderAccordion(item, 0))}
      </Box>
      <Box
        sx={{
          borderRadius: "20px",
          border: url ? "1px solid  #DFE2E5" : "1px solid  transparent",
          boxShadow: "0px 5px 16px 0px rgba(8, 15, 52, 0.05)",
          overflow: "hidden",
        }}
      >
        {renderTagAccordion(tagQuestionData)}
      </Box>
      {showAlert === true ? (
        <Alert
          className="removeIconLessonBooksAlert"
          sx={{
            width: "fit-Content",
            backgroundColor: "#E74C3C",
            color: "#FFF",
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
            alignItems: "center",
            borderRadius: "10px",
            fontWeight: 500,
            fontSize: "16px",
            zIndex: 1000,
            position: "fixed",
            bottom: "10%",
            left: "40%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <span
              style={{
                fontSize: "16px",
                fontWeight: 500,
                color: "#FFF",
              }}
            >
              {errMess.message === "Question answer not exist" &&
                "התשובה לשאלה לא קיימת"}
            </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              style={{ marginRight: "10px" }}
              onClick={
                showAlert
                  ? () => setShowAlert(false)
                  : () => setShowErrorMessage(false)
              }
            >
              <path
                d="M0.93934 16.9393C0.353553 17.5251 0.353553 18.4749 0.93934 19.0607C1.52513 19.6464 2.47487 19.6464 3.06066 19.0607L10 12.1213L16.9393 19.0607C17.5251 19.6465 18.4749 19.6465 19.0607 19.0607C19.6464 18.4749 19.6464 17.5251 19.0607 16.9393L12.1213 10L19.0607 3.06066C19.6464 2.47487 19.6464 1.52513 19.0607 0.93934C18.4749 0.353553 17.5251 0.353553 16.9393 0.93934L10 7.87868L3.06066 0.939342C2.47487 0.353556 1.52513 0.353556 0.93934 0.939342C0.353553 1.52513 0.353553 2.47488 0.93934 3.06066L7.87868 10L0.93934 16.9393Z"
                fill="#FFF"
              />
            </svg>
          </div>
        </Alert>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default AccordionForChapterPrinciple;
