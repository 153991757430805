import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import Header from "views/student/Header";
import Footer from "./Footer";
import NoPageFoundIMG from "../../assets/images/octopus-nopagefound.png";
import { useTheme } from "@mui/material/styles";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router";
import { useAppDispatch } from "Redux/App/hooks";
import { setCurrentTab } from "Redux/feature/StudentHome";
import { getCookie } from "./cookieUtils";

function NoPageFound() {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const navigate = useNavigate();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const maxwidthstyle = isMobile ? "330px" : "430px";

  const userType = getCookie("user_type");

  const navigateToHomePage = () => {
    const destinationUrl =
      userType === "Teacher" ? "/teacher-home" : "/student-home";
    navigate(destinationUrl);
  };

  useEffect(() => {
    if (userType === "Teacher" || " Student") {
      dispatch(setCurrentTab(null));
    }
  });

  return (
    <>
      {userType && <Header />}

      <Box sx={{ overflow: "hidden" }}>
        <Box
          className="page-content"
          sx={{
            overflow: "hidden",
            marginBottom: "-30px",
            marginTop: { xs: "70px", md: "90px" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: { xs: "0px 15px", md: "0px 0px" },
              gap: { xs: "50px", md: "30px" },
              height: "calc(100vh - 200px)",
              minHeight: { xs: "515px", md: "575px" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{ maxWidth: maxwidthstyle, width: "100%" }}
                src={NoPageFoundIMG}
                alt="404"
                loading="lazy"
                title="404"
              />
            </Box>
            <Box
              sx={{
                display: "inline-flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontSize: { xs: "22px", md: "26px" },
                      fontWeight: "400",
                    }}
                  >
                    <FormattedMessage id="we-didnt-find-what-you-were-looking-for" />
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontSize: { xs: "22px", md: "26px" },
                      fontWeight: "700",
                    }}
                  >
                    <FormattedMessage id="want-to-go-back-to-home-page" />
                  </Typography>
                </Box>
              </Box>
              <Button
                variant="contained"
                sx={{
                  display: "flex",
                  padding: "9px 22px",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "4px",
                  borderRadius: "8px",
                  background: "#7A49B9",
                  color: "white",
                }}
                onClick={navigateToHomePage}
              >
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "18px",
                    textAlign: "center",
                    lineHeight: "120%",
                  }}
                >
                  <FormattedMessage id="teacher-home" />
                </Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
}

export default NoPageFound;
