
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosClient from "api/api";

export const WorkGroupData = createAsyncThunk(
  "StudentWorkGroup/WorkGroupData",
  async (data: any) => {
    try {
      const res = await axiosClient.get(
        `get-work-group-channels?student_id=${data?.student_id}&course_id=${data?.course_id}`
      );
      return res?.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const StoreWorkGroupData = createAsyncThunk(
  "StudentWorkGroup/StoreWorkGroupData",
  async (data: any) => {
    try {
      const res = await axiosClient.post(
        `store-student-work-group?student_id=${data?.student_id}&work_group_hour_id=${data.work_group_hour_id}&is_remove=${data.is_remove}`
      );
      return res?.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

interface Channel {
  id: number;
  name: string;
  explanation: string;
  created_at: string;
  updated_at: string;
  work_groups: WorkGroup[];
}

interface WorkGroup {
  id: number;
  group_name: string;
  description: string;
  meeting_date: string;
  product_id: number;
  channel_id: number;
  active: number;
  created_at: string;
  updated_at: string;
  teachers: Teacher[];
}

interface Teacher {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  email_verified_at: string | null;
  disabled_at: string | null;
  role_id: number;
  address: string | null;
  phone_number: string;
  worker_id: string;
  profile_pic: string | null;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  otp_verified_token: string | null;
  is_verified: number;
  professional: number;
  level: string | null;
  conduct: number;
  success_page_flag: string | null;
  walk_through_data: string | null;
  hours: Hour[];
  full_name: string;
}

interface Hour {
  id: number;
  work_group_id: number;
  teacher_id: string;
  start_time: string;
  end_time: string;
  created_at: string;
  updated_at: string;
}

interface WorkGroupTeacher {
  id: string;
  first_name: string;
  last_name: string;
  full_name: string;
  email: string;
  phone_number: string;
  profile_pic: string | null;
  role_id: number;
  worker_id: string;
  updated_at: string;
}

interface WorkGroupTeacherHours {
  id: number;
  work_group_id: number;
  teacher_id: string;
  start_time: string;
  end_time: string;
  created_at: string;
  updated_at: string;
  teacher: WorkGroupTeacher;
  work_groups: WorkGroups;
}

interface WorkGroupChannel {
  id: number;
  name: string;
  explanation: string;
  zoom_link: string;
  updated_at: string;
}

interface WorkGroups {
  id: number;
  group_name: string;
  description: string;
  meeting_date: string;
  created_at: string;
  updated_at: string;
  channel: WorkGroupChannel;
}

interface StudentWorkGroups {
  id: number;
  work_group_teacher_hours_id: number;
  student_id: string;
  created_at: string;
  updated_at: string;
  work_group_teacher_hours: WorkGroupTeacherHours[];
}

// interface StudentWorkGroupsState {
//   studentworkgroups: StudentWorkGroup[];
// }

interface InitialState {
  channels: Channel[];
  message:any[],
  studentworkgroups: StudentWorkGroups[];
  loading: boolean;
  error: string | null;
}

const initialState: InitialState = {
  channels: [],
  message:[],
  studentworkgroups: [],
  loading: false,
  error: null,
};

const StudentWorkGroup = createSlice({
  name: "student-work-group-data",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(WorkGroupData.pending, (state) => {
        return {
          ...state,
          loading: true,
          message:[]
        };
      })
      .addCase(WorkGroupData.fulfilled, (state, action) => {

        // Extract channels and studentworkgroups from the payload
        // const channels = action.payload.channelData;
        // const channels = Object.keys(action.payload)
        //   .filter(key => !isNaN(Number(key))) // Filter numeric keys
        //   .map(key => action.payload[key].channel); // Map to channel objects

        // const studentworkgroups = action.payload.studentworkgroups;

        return {
          ...state,
          channels : action.payload.channelData,
          studentworkgroups : action.payload.studentworkgroups,
          message:action.payload.message,
          loading: false,
        };
      })
      .addCase(WorkGroupData.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          // error: action.error.message,
        };
      })
      .addCase(StoreWorkGroupData.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(StoreWorkGroupData.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(StoreWorkGroupData.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          // error: action.error.message,
        };
      });
  },
});

export default StudentWorkGroup.reducer;
