import { useEffect, useState, FormEvent } from "react";
import {
  Button,
  TextField,
  InputLabel,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Box,
  Typography,
  Link,
  Alert,
  CircularProgress,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "Redux/App/hooks";
import { FetchClientLogin, updateWorkerId } from "Redux/feature/UserAuth";
import openEyes from "../../assets/images/openEyes.svg";
import closeEyes from "../../assets/images/closeEyes.svg";
import { removeAllCookies, setCookie } from "views/common/cookieUtils";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [buttonDisable, setButtonDisable] = useState(false);
  const location = useLocation();
  const [showAlert, setShowAlert] = useState(false);
  const [workerId, setWorkerId] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const step = useAppSelector(
    (state: any) => state.BoardingSlice.personalDetails
  );

  useEffect(() => {
    if (step.steps_done === 6 && location.pathname === "/on-boarding") {
      navigate("/student-home");
    }
  }, [step, location.pathname, navigate]);

  useEffect(() => {
    dispatch(updateWorkerId(workerId));
  }, [workerId, dispatch]);

  const responseMessage = useAppSelector(
    (state: any) => state.UserAuth.message
  );

  // useEffect(() => {
  //   if (isLoggedIn) {
  //     localStorage.clear();
  //     removeAllCookies();
  //     window.location.reload();
  //   }
  //   // dispatch(ResetStudentHome());
  // }, [isLoggedIn]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    setButtonDisable(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    // Dispatch login action
    dispatch(
      FetchClientLogin({
        user_id: workerId,
        password: data.get("password") as string,
      })
    ).then((res: any) => {
      setButtonDisable(false);

      if (res.payload.status) {
        // Clear cookies first before setting new ones
        clearCookie("id");
        clearCookie("accessToken");
        clearCookie("user_type");
        clearCookie("user_name");
        clearCookie("product_category_id");

        // Set new cookie values
        setCookie("id", res.payload.data.id, 4, "h"); // Expires in 1 mour
        setCookie("accessToken", res.payload.token, 4, "h");
        setCookie("user_type", res.payload.data.user_type, 4, "h");
        setCookie("user_name", res.payload.data.full_name, 4, "h");
        setCookie("is_onboarding_redirect", res.payload.data.is_onboarding_redirect, 4, "h");
        setCookie(
          "product_category_id",
          res.payload.data.product_category_id,
          4,
          "h"
        );

        // Navigate based on user type and onboarding status
        res.payload.data.user_type === "Teacher"
          ? navigate("/teacher-home")
          : res.payload.data.is_onboarding_redirect === 1
            ? navigate("/on-boarding")
            : navigate("/student-home");
      } else {
        setShowAlert(true);
      }

      // Hide the alert after 4 seconds
      setTimeout(() => {
        setShowAlert(false);
      }, 4000);
    });
  };

  // Function to clear cookies
  const clearCookie = (name: string) => {
    document.cookie = `${name}=; Max-Age=-99999999;`;
  };

  const handleForgotPassword = () => {
    navigate("/forgot-password");
  };

  return (
    <>
      <Box
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          width={{ xs: "100%", sm: "394px" }}
          sx={{
            mx: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            pt: "15px",
            pb: "35px",
          }}
        >
          <Typography
            sx={{ fontSize: { md: "32px", xs: "24px" }, fontWeight: 700, marginTop: "30px" }}
            className="login-title"
          >
            <FormattedMessage id="login-title" />
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 1, width: "100%" }}
          >
            <Typography
              variant="subtitle1"
              textAlign={{ xs: "center", md: "right" }}
            >
              <FormattedMessage id="sample-text" />
            </Typography>

            <InputLabel
              htmlFor="userId"
              sx={{ marginTop: "20px", marginBottom: "5px" }}
            >
              <FormattedMessage id="userName" />
            </InputLabel>
            <TextField
              margin="none"
              required
              fullWidth
              id="userId"
              name="userId"
              value={workerId}
              onChange={(e) => {
                setWorkerId(e.target.value);
              }}
              type="text"
              size="small"
              sx={{ fontSize: "16px", height: "38px" }}
            />

            <InputLabel
              sx={{ marginTop: "25px", marginBottom: "5px" }}
              htmlFor="password"
            >
              <FormattedMessage id="password" />
            </InputLabel>

            <FormControl sx={{ mr: 0, width: "100%" }} variant="outlined">
              <OutlinedInput
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <img
                          src={closeEyes}
                          alt="Close Eyes Icon"
                          loading="lazy"
                          title="Close Eyes Icon"
                          style={{ width: "100%", height: "100%" }}
                        />
                      ) : (
                        <img
                          src={openEyes}
                          alt="Open Eyes Icon"
                          loading="lazy"
                          title="Open Eyes Icon"
                          style={{ width: "100%", height: "100%" }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                margin="none"
                required
                fullWidth
                id="password"
                name="password"
                onChange={(e) => setPassword(e.target.value)}
                size="small"
                placeholder="הכנסת סיסמה"
                sx={{ fontSize: "16px" }}
                aria-describedby="component-error-text"
              />
            </FormControl>
            {showAlert && (
              <Alert
                className="removeIconLessonBooksAlert"
                sx={{
                  width: "auto",
                  height: "auto",
                  color: "#E74C3C",
                  fontWeight: 600,
                  fontSize: "16px",
                  padding: "0px",
                  backgroundColor: "transparent",
                }}
              >
                {responseMessage}
              </Alert>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ marginTop: "40px", height: "40px", fontSize: "18px" }}
              disabled={password === "" || buttonDisable}
            >
              {/* <FormattedMessage id="login-system" /> */}

              {buttonDisable ? (
          <CircularProgress size={24} sx={{ color: "#fff" }} />
        ) : (
          <FormattedMessage id="login-system" />
        )}
            </Button>
          </Box>
          {workerId === "" ? (
            <Typography
              color="text.secondary"
              variant="body2"
              sx={{
                marginTop: "17px",
                fontSize: "18px",
                cursor: "not-allowed",
              }}
            >
              <FormattedMessage id="forgot-password" />
            </Typography>
          ) : (
            <Link
              color="primary"
              onClick={handleForgotPassword}
              variant="body2"
              sx={{
                marginTop: "17px",
                fontSize: "18px",
                cursor: "pointer",
              }}
            >
              <FormattedMessage id="forgot-password" />
            </Link>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Login;
