import React, { useEffect, useState } from "react";
import { Typography, Button, Box, TextField, Stack } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { useAppDispatch, useAppSelector } from "../../../Redux/App/hooks";
import {
  GetAllSubmittedQuestion,
  GetAllSubmittedQuestionForOnlineExam,
  SaveQuestionNotes,
} from "Redux/feature/Questions";
import { useSearchParams } from "react-router-dom";
import { getCookie } from "views/common/cookieUtils";

type Props = {
  question_id: any;
};

const QuestionSideBar = ({ question_id }: Props) => {
  const {
    questions,
    container_data,
    activeQuestion,
    correctAnswerDetails,
    subject,
  }: any = useAppSelector((state) => state.Questions);

  const totalCorrectAnswer: any = useAppSelector(
    (state) => state.Questions.totalCorrectAnswers
  );
  const intl = useIntl();
  const insightTextFieldPlaceholder = intl.formatMessage({
    id: "insight-text-placeholder",
  });
  const [notes, setNotes] = useState("");
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [editNotes, setEditNotes] = useState<boolean>(false);
  const [getEditNotes, setGetEditNotes] = useState<any>();
  const user_id = getCookie("id");
  const containerId: any = searchParams.get("container_type_id");
  const assignmentId: any = searchParams.get("assignment_id");
  const is_cluster = searchParams.get("is_cluster");

  const addedFlag = useAppSelector(
    (state) => state.Questions.setFlagProgressBarNotes
  );
  const selectedChapter: number = useAppSelector(
    (state) => state?.ChapterExamSlice?.selectedChapter
  );
  const selectedCourse: any = useAppSelector(
    (state) => state.BoardExam.selectedCourse
  );
  const submittedNotes: any = useAppSelector(
    (state) => state.Questions.submittedNotes
  );
  const defaultCourseId = getCookie("default_course_id");
  const allQuestionContainerID = useAppSelector(
    (state) => state.OnlinePracticeExamSlice?.saveOnlinePracticeInfo.id
  );
  const matchedObject = submittedNotes.find(
    (item: { question_id: any }) => item?.question_id == question_id?.id
  );
  const handleClickAddNotes = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNotes(e.target.value);
  };
  useEffect(() => {
    if (addedFlag) {
      setEditNotes(false);
      setNotes("");
    }
    if (matchedObject) {
      setNotes(matchedObject?.notes);
      setEditNotes(true);
    }
  }, [question_id, matchedObject]);
  const handleEditNotes = () => {
    setEditNotes(false);
  };
  const handleNotesClick = () => {
    if (editNotes) {
      dispatch(
        SaveQuestionNotes({
          user_id: user_id ? user_id : null,
          question_id: question_id.id,
          course_id: defaultCourseId ? defaultCourseId : selectedCourse,
          container_id:
            searchParams.get("container_type_id") == "7" ||
            searchParams.get("container_type_id") == "8" ||
            searchParams.get("container_type_id") == "10"||searchParams.get("container_type_id") == "11"
              ? container_data?.id
              : searchParams.get("container_type_id") == "5"
              ? selectedChapter
              : searchParams.get("container_id"),
          notes: notes,
          question_number: activeQuestion + 1,
          id: getEditNotes.id,
          activeQuestion: activeQuestion + 1,
          assignment_id: assignmentId,
          // ...(containerId == "10" && { assignment_id: assignmentId }),
        })
      ).then((resp: any) => {
        setEditNotes(true);
        setNotes(notes);
      });
    } else {
      dispatch(
        SaveQuestionNotes({
          user_id: user_id ? user_id : null,
          question_id: question_id.id,
          course_id: defaultCourseId ? defaultCourseId : selectedCourse,
          container_id:
            searchParams.get("container_type_id") == "7" ||
            searchParams.get("container_type_id") == "8" ||
            searchParams.get("container_type_id") == "10" ||searchParams.get("container_type_id") == "11"
              ? container_data?.id
              : searchParams.get("container_type_id") == "5"
              ? selectedChapter
              : searchParams.get("container_id"),
          notes: notes,
          question_number: activeQuestion + 1,
          activeQuestion: activeQuestion + 1,
          assignment_id: assignmentId,
          // ...(containerId == "10" && { assignment_id: assignmentId }),
        })
      ).then((res: any) => {
        if (res.payload.status === true) {
          if (
            searchParams.get("container_type_id") == "7" ||
            searchParams.get("container_type_id") == "8"
          ) {
            setEditNotes(true);
            dispatch(
              GetAllSubmittedQuestionForOnlineExam({
                student_id: user_id,
                practice_id: allQuestionContainerID,
                onlineExamType: "practice",
              })
            );
          } else if (searchParams.get("container_type_id") == "10"||searchParams.get("container_type_id") == "11") {
            dispatch(
              GetAllSubmittedQuestion({
                user_id: user_id,
                container_id: container_data.id,
                assignment_id: assignmentId,
                type: "Online Exam",
              })
            ).then((resp) => {
              setEditNotes(true);
              setGetEditNotes(res?.payload?.data);
            });
          } else {
            dispatch(
              GetAllSubmittedQuestion({
                user_id: user_id,
                ...(containerId == "5" && { assignment_id: assignmentId }),
                container_id:
                  containerId == "5"
                    ? selectedChapter
                    : searchParams.get("container_id"),
              })
            ).then((resp: any) => {
              setEditNotes(true);
              setGetEditNotes(res?.payload?.data);
            });
          }
        } else {
          setEditNotes(false);
        }
      });

      setNotes(notes);
    }
  };
  const fetchAllSubmittedQuestionAnswer: any = useAppSelector(
    (state) => state.Questions.fetchAllSubmittedQuestions
  );
  const setNumericBar: boolean = useAppSelector(
    (state) => state.Questions.numericBar
  );

  const allQuestionAttempt: boolean = useAppSelector(
    (state) => state.Questions.isAllQuestionAttempt
  );

  return (
    <>
      {allQuestionAttempt && !setNumericBar && (
        <Box
          sx={{
            width: { xs: "350px", md: "300px" },
            height: {
              xs: "330px",
              sm: "330px",
              md: "100%",
              lg: "100%",
            },
            backgroundColor: {
              xs:
                containerId === "7" || containerId === "8" ? "#FFF" : "#E8EBED",
              md: "#E8EBED",
            },
            padding: "15px",
            boxSizing: "border-box",
          }}
        >
          {container_data.type == "Series of Question" ||(containerId === "7"&&is_cluster)||
          container_data.type === "Split Screen" ? (
            <>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  boxSizing: "border-box",
                }}
              >
                <Typography
                  sx={{
                    display: { xs: "none", md: "block" },
                    fontSize: "20px",
                    fontWeight: "700",
                    lineHeight: "27.2px",
                  }}
                >
                  תחקור תרגול
                </Typography>

                <Box
                  sx={{
                    display: { xs: "none", md: "flex" },
                    gap: "20px",
                    pt: "10px",
                  }}
                >
                  {/* box1 */}
                  <Box
                    sx={{
                      width: "122px",
                      height: "80px",
                      borderRadius: "10px",
                      backgroundColor: "#FFFFFF",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="h3"
                      color="info"
                      sx={{
                        fontSize: "24px",
                        fontWeight: 700,
                        color: "#3397DB",
                      }}
                    >
                      {container_data.total_examtime}
                    </Typography>
                    <Typography variant="caption" color="#AEB5BC">
                      <FormattedMessage id="practice-time" />
                    </Typography>
                  </Box>

                  {/* box2 */}
                  <Box
                    sx={{
                      width: "122px",
                      height: "80px",
                      borderRadius: "10px",
                      backgroundColor: "#FFFFFF",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="h3"
                      color="info"
                      sx={{
                        fontSize: "24px",
                        fontWeight: 700,
                        color: "#3397DB",
                      }}
                    >
                      {totalCorrectAnswer}
                      <span style={{ fontWeight: 400, fontSize: "12px" }}>
                        /
                        {searchParams.get("container_type_id") == "5" ||
                        searchParams.get("container_type_id") == "10" ||searchParams.get("container_type_id") == "11"
                          ? Object.keys(correctAnswerDetails).length
                          : questions.length}
                      </span>
                    </Typography>
                    <Typography variant="caption" color="#AEB5BC">
                      <FormattedMessage id="correct-answers" />
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </>
          ) : (
            <></>
          )}

          <Box
            sx={{
              paddingTop: "30px",
              display: { xs: "none", sm: "none", md: "block", lg: "block" },
            }}
          >
            <Typography
              mt={2}
              style={{
                fontSize: "20px",
                fontWeight: "700",
                lineHeight: "27.2px",
                marginBottom:
                  containerId === "7" || containerId === "8" ? "0px" : "5px",
              }}
            >
              {containerId === "7" || containerId === "8" ? (
                <>
                  <FormattedMessage id="insight-question-title" />
                </>
              ) : (
                <>
                  <FormattedMessage id="question" />
                  <span style={{ marginRight: "5px" }}>
                    {activeQuestion + 1}
                  </span>
                </>
              )}
            </Typography>
            <Typography
              variant="h4"
              mt={3}
              mb={1}
              sx={{ fontSize: "16px", fontWeight: 500 }}
            ></Typography>
            <Box
              sx={{
                width: "122px",
                height: "80px",
                borderRadius: "10px",
                backgroundColor: "#FFFFFF",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {fetchAllSubmittedQuestionAnswer?.map((item: any, index: any) => (
                <>
                  <Typography
                    variant="h3"
                    color="info"
                    key={index}
                    sx={{
                      fontSize: "24px",
                      fontWeight: 700,
                      color: "#3397DB",
                    }}
                  >
                    {activeQuestion === index ? item.time_taken_for_answer : ""}
                  </Typography>
                </>
              ))}

              <Typography variant="caption" color="#AEB5BC">
                <FormattedMessage id="answer-question" />
              </Typography>
            </Box>
          </Box>
          <Typography
            variant="caption"
            color="#222525"
            display={{ xs: "block", md: "none" }}
            sx={{ marginTop: "35px", fontSize: "18px", fontWeight: "600" }}
          >
            <FormattedMessage id="insight-question-title" />
          </Typography>
          <Box className={`${activeQuestion == 0 ? "question-option-4 " : ""}`}>
            <Typography
              variant="h4"
              mt={2.5}
              mb={1}
              sx={{ fontSize: "16px", fontWeight: 500 }}
            >
              <FormattedMessage id="insight-question-text" />
            </Typography>
            <TextField
              id="outlined-multiline-static"
              multiline
              rows={4}
              fullWidth
              defaultValue=""
              disabled={editNotes}
              sx={{
                backgroundColor: "#fff",
                fontSize: "12px",
              }}
              onChange={handleClickAddNotes}
              placeholder={insightTextFieldPlaceholder}
              value={notes}
            />
            <Stack>
              {editNotes ? (
                <Button
                  variant="text"
                  sx={{
                    fontWeight: 400,
                    fontSize: "16px",
                    marginBottom: "28px",
                    minWidth: "1px",
                    px: "0",
                    marginRight: "auto",
                    backgroundColor: "transparent !important",
                    textDecoration: "underline !important",
                  }}
                  onClick={handleEditNotes}
                >
                  <FormattedMessage id="edit" />
                </Button>
              ) : (
                <Button
                  variant="text"
                  sx={{
                    fontWeight: 400,
                    fontSize: "16px",
                    marginBottom: "28px",
                    minWidth: "1px",
                    px: "0",
                    marginRight: "auto",
                    backgroundColor: "transparent !important",
                    textDecoration: "underline !important",
                  }}
                  onClick={handleNotesClick}
                >
                  <FormattedMessage id="insight-save" />
                </Button>
              )}
            </Stack>
          </Box>
        </Box>
      )}
      {allQuestionAttempt && setNumericBar && (
        <Box
          sx={{
            width: { xs: "350px", md: "300px" },
            backgroundColor: "#E8EBED",
            padding: "15px",
            boxSizing: "border-box",
            height: "100%",
          }}
        >
          {
            <>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  boxSizing: "border-box",
                  marginTop: containerId == "10"||containerId == "11" ? "22px" : "0px",
                }}
              >
                <Typography
                  style={{
                    fontSize: "20px",
                    fontWeight: "700",
                    lineHeight: "27.2px",
                  }}
                >
                  תחקור תרגול
                </Typography>

                <Box sx={{ display: "flex", gap: "20px", pt: "10px" }}>
                  {/* box1 */}
                  {/* box2 */}
                  <Box
                    sx={{
                      width: "122px",
                      height: "80px",
                      borderRadius: "10px",
                      backgroundColor: "#FFFFFF",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="h3"
                      color="info"
                      sx={{
                        fontSize: "24px",
                        fontWeight: 700,
                        color: "#3397DB",
                      }}
                    >
                      {totalCorrectAnswer}

                      <span style={{ fontWeight: 400, fontSize: "12px" }}>
                        /
                        {searchParams.get("container_type_id") == "5" ||
                        searchParams.get("container_type_id") == "10" || searchParams.get("container_type_id") == "11"
                          ? Object.keys(correctAnswerDetails).length
                          : questions.length}
                      </span>
                    </Typography>
                    <Typography
                      variant="caption"
                      color="#AEB5BC"
                      style={{
                        fontFamily: "Noto Sans Hebrew",
                        fontSize: "12px",
                        fontWeight: 400,
                        lineHeight: "16px",
                        letterSpacing: "0px",
                        textAlign: "center",
                      }}
                    >
                      <FormattedMessage id="correct-answers" />
                    </Typography>
                  </Box>

                  {searchParams.get("container_type_id") == "5" ||
                  searchParams.get("container_type_id") == "10"|| searchParams.get("container_type_id") == "11" ? (
                    <Box
                      sx={{
                        width: "122px",
                        height: "80px",
                        borderRadius: "10px",
                        backgroundColor: "#FFFFFF",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="h3"
                        color="info"
                        sx={{
                          fontSize: "24px",
                          fontWeight: 700,
                          color: "#3397DB",
                        }}
                      >
                        {totalCorrectAnswer && correctAnswerDetails
                          ? (() => {
                              const percentage =
                                (totalCorrectAnswer * 100) /
                                Object.keys(correctAnswerDetails).length;

                              if (percentage) {
                                return Math.round(percentage) + "%";
                              }

                              return percentage + "%";
                            })()
                          : "0%"}
                      </Typography>
                      <Typography variant="caption" color="#AEB5BC">
                        {subject ? (
                          <FormattedMessage id={subject} />
                        ) : (
                          <FormattedMessage id="subjects" />
                        )}
                      </Typography>
                    </Box>
                  ) : (
                    ""
                  )}
                </Box>
              </div>
            </>
          }
          <Typography
            mt={2}
            style={{
              fontSize: "20px",
              fontWeight: "700",
              lineHeight: "27.2px",
              marginBottom: "5px",
            }}
          >
            <FormattedMessage id="question" />
            <span style={{ marginRight: "5px" }}>{activeQuestion + 1}</span>
          </Typography>
          <Typography
            variant="h4"
            mt={2.5}
            mb={1}
            sx={{ fontSize: "16px", fontWeight: 500 }}
          >
            <FormattedMessage id="insight-question-text" />
          </Typography>
          <TextField
            id="outlined-multiline-static"
            multiline
            rows={4}
            disabled={editNotes}
            fullWidth
            defaultValue=""
            sx={{
              backgroundColor: "#fff",
              fontSize: "12px",
            }}
            onChange={handleClickAddNotes}
            placeholder={insightTextFieldPlaceholder}
            value={notes}
          />
          <Stack>
            {editNotes ? (
              <Button
                variant="text"
                sx={{
                  fontWeight: 400,
                  fontSize: "16px",
                  marginBottom: "28px",
                  minWidth: "1px",
                  px: "0",
                  marginRight: "auto",
                  backgroundColor: "transparent !important",
                  textDecoration: "underline !important",
                }}
                onClick={handleEditNotes}
              >
                <FormattedMessage id="edit" />
              </Button>
            ) : (
              <Button
                variant="text"
                sx={{
                  fontWeight: 400,
                  fontSize: "16px",
                  marginBottom: "28px",
                  minWidth: "1px",
                  px: "0",
                  marginRight: "auto",
                  backgroundColor: "transparent !important",
                  textDecoration: "underline !important",
                }}
                onClick={handleNotesClick}
              >
                <FormattedMessage id="insight-save" />
              </Button>
            )}
          </Stack>
        </Box>
      )}
    </>
  );
};

export default QuestionSideBar;
