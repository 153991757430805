import { useEffect, useState } from "react";
import {
  Button,
  InputLabel,
  FormHelperText,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import { Error, CheckCircleOutlined } from "@mui/icons-material";
import openEyes from "../../assets/images/openEyes.svg";
import closeEyes from "../../assets/images/closeEyes.svg";
import { red } from "@mui/material/colors";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "Redux/App/hooks";
import { FetchClientSetPassword } from "Redux/feature/UserAuth";

const CreatePassword = () => {
  const workerId = useAppSelector((state) => state.UserAuth.workerId);
  const oTPVerifiedToken = useAppSelector(
    (state) => state.UserAuth.oTPVerifiedToken
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [submitted, setSubmitted] = useState(false);

  const [values, setValues] = useState<{
    password: string;
    verifyPassword: string;
    showPassword: boolean;
    showVerifyPassword: boolean;
  }>({
    password: "",
    verifyPassword: "",
    showPassword: false,
    showVerifyPassword: false,
  });

  const errorMessage = {
    required: <FormattedMessage id="required-field-message" />,
    digit: <FormattedMessage id="digits-field-message" />,
    capitalLetter: <FormattedMessage id="capital-letter-field-message" />,
    length: <FormattedMessage id="length8-field-message" />,
    verify: <FormattedMessage id="verification-field-message" />,
  };

  const handleChange = (type: string, value: string) => {
    if (type === "password") setValues({ ...values, password: value });
    if (type === "verifyPassword")
      setValues({ ...values, verifyPassword: value });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setSubmitted(true);
    const data = new FormData(event.currentTarget);
    if (isError(values.password) || isError(values.verifyPassword, true)) {
      return;
    }
    dispatch(
      FetchClientSetPassword({
        otp_verified_token: oTPVerifiedToken,
        username: workerId,
        password: data.get("verify_password"),
      })
    ).then((res) => {
      res.payload.status ? navigate("/") : alert(res.payload.message);
    });
  };

  useEffect(() => {
    if (values.password === "" && values.verifyPassword === "") {
      setSubmitted(false);
    }
  }, [values]);

  useEffect(() => {
    if (workerId === "") {
      navigate("/");
    }
  }, [workerId]);

  const isError = (value: string, verifyCheck?: boolean) => {
    return (
      value === "" ||
      value.length < 8 ||
      !/[A-Z]/.test(value) ||
      !/[0-9]/.test(value) ||
      (verifyCheck && value !== values.password)
    );
  };

  const getErrorMessage = (value: string, verifyCheck?: boolean) => {
    if (value === "") return errorMessage.required;
    if (!/[A-Z]/.test(value)) return errorMessage.capitalLetter;
    if (!/[0-9]/.test(value)) return errorMessage.digit;
    if (value.length < 8) return errorMessage.length;
    if (verifyCheck && value !== values.password) return errorMessage.verify;
    return "";
  };

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        width={{ xs: "100%", sm: "394px" }}
        height={{ xs: "100%", md: "calc(100vh - 215px)" }}
        sx={{
          mx: 4,
          marginTop: "85px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: "15px",
          pb: "35px",
        }}
      >
        <Box width="100%">
          <Typography sx={{ fontSize: "32px", fontWeight: 700 }}>
            <FormattedMessage id="setting-password" />
          </Typography>
        </Box>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ mt: 1, width: "100%" }}
        >
          <Typography variant="subtitle1">
            <FormattedMessage id="password-rules-info" />
            <ul>
              {submitted === false ? (
                <li style={{ color: "#4A5056" }}>
                  <FormattedMessage id="length8-rule" />
                </li>
              ) : values.password.length < 8 ? (
                <li style={{ color: "#E74C3C" }}>
                  <FormattedMessage id="length8-rule" />
                </li>
              ) : (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CheckCircleOutlined
                    sx={{ ml: "3px", marginRight: "-30px", color: "#33CD75" }}
                  />
                  <label>
                    <FormattedMessage id="length8-rule" />
                  </label>
                </Box>
              )}
              {submitted === false ? (
                <li style={{ color: "#4A5056" }}>
                  <FormattedMessage id="digits-rule" />
                </li>
              ) : !/[0-9]/.test(values.password) ? (
                <li style={{ color: red[700] }}>
                  <FormattedMessage id="digits-rule" />
                </li>
              ) : (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CheckCircleOutlined
                    sx={{ ml: "3px", marginRight: "-30px", color: "#33CD75" }}
                  />
                  <label>
                    <FormattedMessage id="digits-rule" />
                  </label>
                </Box>
              )}
              {submitted === false ? (
                <li style={{ color: "#4A5056" }}>
                  <FormattedMessage id="capital-letter-rule" />
                </li>
              ) : !/[A-Z]/.test(values.password) ? (
                <li style={{ color: red[700] }}>
                  <FormattedMessage id="capital-letter-rule" />
                </li>
              ) : (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CheckCircleOutlined
                    sx={{ ml: "3px", marginRight: "-30px", color: "#33CD75" }}
                  />
                  <label>
                    <FormattedMessage id="capital-letter-rule" />
                  </label>
                </Box>
              )}
            </ul>
          </Typography>
          <InputLabel sx={{ marginTop: "25px" }} htmlFor="password">
            <FormattedMessage id="password" />
          </InputLabel>
          <FormControl
            error={submitted && isError(values.password)}
            sx={{ m: 1, mr: 0, width: "100%" }}
            variant="outlined"
          >
            <OutlinedInput
              value={values.password}
              onChange={(e) => handleChange("password", e.target.value)}
              type={values.showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      setValues({
                        ...values,
                        showPassword: !values.showPassword,
                      })
                    }
                  >
                    {values.showPassword ? (
                      <img
                        src={closeEyes}
                        alt="Close Eyes Icon"
                        loading="lazy"
                        title="Close Eyes Icon"
                        style={{ width: "100%", height: "100%" }}
                      />
                    ) : (
                      <img
                        src={openEyes}
                        alt="Open Eyes Icon"
                        loading="lazy"
                        title="Open Eyes Icon"
                        style={{ width: "100%", height: "100%" }}
                      />
                    )}
                  </IconButton>
                  {submitted && isError(values.password) && (
                    <Error
                      sx={{
                        position: "absolute",
                        left: "-30px",
                        color: "#E74C3C",
                      }}
                    />
                  )}
                </InputAdornment>
              }
              margin="none"
              fullWidth
              id="password"
              name="password"
              size="small"
              placeholder="הגדרת סיסמה"
              sx={{ fontSize: "16px" }}
              aria-describedby="component-error-text"
            />
            <FormHelperText
              style={{ textAlign: "right", color: "#E74C3C" }}
              id="component-error-text"
            >
              {submitted && getErrorMessage(values.password)}
            </FormHelperText>
          </FormControl>

          <InputLabel sx={{ marginTop: "20px" }} htmlFor="verify_password">
            <FormattedMessage id="password-verification" />
          </InputLabel>
          <FormControl
            error={submitted && isError(values.verifyPassword, true)}
            sx={{ m: 1, mr: 0, width: "100%" }}
            variant="outlined"
          >
            <OutlinedInput
              value={values.verifyPassword}
              onChange={(e) => handleChange("verifyPassword", e.target.value)}
              type={values.showVerifyPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      setValues({
                        ...values,
                        showVerifyPassword: !values.showVerifyPassword,
                      })
                    }
                  >
                    {values.showVerifyPassword ? (
                      <img
                        src={closeEyes}
                        alt="Close Eyes Icon"
                        loading="lazy"
                        title="Close Eyes Icon"
                        style={{ width: "100%", height: "100%" }}
                      />
                    ) : (
                      <img
                        src={openEyes}
                        alt="Open Eyes Icon"
                        loading="lazy"
                        title="Open Eyes Icon"
                        style={{ width: "100%", height: "100%" }}
                      />
                    )}
                  </IconButton>
                  {submitted && isError(values.verifyPassword, true) && (
                    <Error
                      sx={{
                        position: "absolute",
                        left: "-30px",
                        color: "#E74C3C",
                      }}
                    />
                  )}
                </InputAdornment>
              }
              margin="none"
              // required
              fullWidth
              id="verify_password"
              name="verify_password"
              size="small"
              placeholder="הגדרת סיסמה"
              sx={{ fontSize: "16px" }}
            />
            <FormHelperText
              style={{ textAlign: "right", color: "#E74C3C" }}
              id="component-error-text"
            >
              {submitted && getErrorMessage(values.verifyPassword, true)}
            </FormHelperText>
          </FormControl>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: "25px", mb: 2, height: "40px", fontSize: "18px" }}
          >
            <FormattedMessage id="login-system" />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CreatePassword;
