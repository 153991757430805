import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosClient from "api/api";

interface changeStudentAssignmentStatus {
  student_id?: string;
  assignment_id: string;
  status: number;
  meeting_id: number | undefined;
  course_id: string;
  teacher_id: string | null;
}
interface switchAssignmentStudent {
  teacher_id: string | null;
  student_id?: string;
  course_id: string;
  meeting_id: number | undefined;
  assignment_id: number | undefined;
}
interface changeStatusAtomicContainer {
  assignment_id: string;
  teacher_id: string | null;
  student_id: string;
  course_id: string;
  meeting_id: number | undefined;
  atomic_unit_id: string;
  status: number;
  type: string;
}
interface saveStudentLevelData {
  student_id: string | null | undefined;
  factor_score: string;
  id: string;
}
interface saveAssignment {
  meeting_id: string;
  is_container_available: number;
  teacher_id: string | null;
  student_id: string | null;
  course_id: string;
  assignment_data: any[];
  atomic_data: any[];
  container_data: any[];
}
interface getclassassignmentlist {
  teacher_id?: string | null;
  course_id?: string;
  availability?: string;
  left?: number | string | undefined;
  urgent?: string;
  taskDone?: number | string | undefined;
  lastExecution?: number | string;
}

interface onboardingInfo{
  time_to_spend: number;
}

export const FetchStudentsBasedOnCourse = createAsyncThunk(
  "TeacherSlice/getStudentsBasedOnCourse",
  async (data: any) => {
    try {
      const queryParams: string[] = [];
      if (data.everyone) {
        queryParams.push(`everyone=${data.everyone}`);
      }
      if (data.search) {
        queryParams.push(`search=${data.search}`);
      }
      if (data.excellentInConductAndProfessional) {
        queryParams.push(
          `excellentInConductAndProfessional=${data.excellentInConductAndProfessional}`
        );
      }
      if (data.excellentInConduct) {
        queryParams.push(`excellentInConduct=${data.excellentInConduct}`);
      }
      if (data.excelInMyProfession) {
        queryParams.push(`excelInMyProfession=${data.excelInMyProfession}`);
      }
      if (data.fail) {
        queryParams.push(`fail=${data.fail}`);
      }
      if (data.conduct) {
        queryParams.push(`conduct=${data.conduct}`);
      }
      if (data.professional) {
        queryParams.push(`professional=${data.professional}`);
      }

      const queryString =
        queryParams.length > 0 ? "&" + queryParams.join("&") : "";
      const url = `get-students-by-course?course_id=${data.course_id}${queryString}`;

      const res = await axiosClient.get(url);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const FetchStudentsDashboard = createAsyncThunk(
  "TeacherSlice/getStudentsDashboardData",
  async (data: {
    student_id: string | null | undefined;
    course_id: string;
  }) => {
    try {
      const res = await axiosClient.get(
        `get-students-dashboard-data?student_id=${data.student_id}&course_id=${data.course_id}`
      );
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const FetchAtomicContainerDataTeacherSide = createAsyncThunk(
  "TeacherSlice/fetchAtomicContainerDataTeacherSide",
  async (data: {
    id: string;
    course_id: string;
    teacher_id: string | null;
  }) => {
    try {
      const res = await axiosClient.get(
        `get-atomic-container-teacher?student_id=${data.id}&course_id=${data.course_id}&teacher_id=${data.teacher_id}`
      );
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const ChangeStudentAssignmentStatus = createAsyncThunk(
  "TeacherSlice/changeAssignmentStatus",
  async (data: changeStudentAssignmentStatus) => {
    try {
      const res = await axiosClient.post(
        "change-student-assignment-status",
        data
      );
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const SwitchAssignmentStudent = createAsyncThunk(
  "TeacherSlice/switchAssignmentStudent",
  async (data: switchAssignmentStudent) => {
    try {
      const res = await axiosClient.post("switch-students-assignment", data);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const ChangeStatusAtomicContainer = createAsyncThunk(
  "TeacherSlice/changeStatusAtomicContainer",
  async (data: changeStatusAtomicContainer, { rejectWithValue }) => {
    try {
      const res = await axiosClient.post("change-atomic-unit-status", data);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        const status_code = error.response.status;
        return rejectWithValue({
          statusCode: status_code,
          message: error.response.data.message,
        });
      } else {
        throw error;
      }
    }
  }
);

export const GetStudentLevelData = createAsyncThunk(
  "TeacherSlice/studentLevelData",
  async (data: {
    student_id: string | null | undefined;
    course_id: string;
  }) => {
    try {
      const res = await axiosClient.get(
        `get-student-level-data?student_id=${data.student_id}&course_id=${data.course_id}`
      );
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const SaveStudentLevelData = createAsyncThunk(
  "TeacherSlice/changeStudentLevelData",
  async (data: saveStudentLevelData) => {
    try {
      const res = await axiosClient.post("save-student-level-data", data);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const SaveAssignment = createAsyncThunk(
  "TeacherSlice/saveAssignmentStudent",
  async (data: saveAssignment) => {
    try {
      const res = await axiosClient.post("save-assignment-for-student", data);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const GetClassAssignmentList = createAsyncThunk(
  "TeacherSlice/classAssignmentList",
  async (data: any) => {
    try {
      let url = `get-class-assignment-list?teacher_id=${data.teacher_id}&course_id=${data.course_id}`;
      if (
        data.availability !== undefined &&
        data.left !== undefined &&
        data.urgent !== undefined &&
        data.taskDone !== undefined &&
        data.lastExecution !== undefined
      ) {
        url += `&availability=${data.availability}&left=${data.left}&urgent=${data.urgent}&were_carried_out=${data.taskDone}&last_completed_task=${data.lastExecution}`;
      } else if (data.lastExecution) {
        url = `get-class-assignment-list?teacher_id=${data.teacher_id}&course_id=${data.course_id}&last_completed_task=${data.lastExecution}`;
      } else if (data.left >= 0) {
        url = `get-class-assignment-list?teacher_id=${data.teacher_id}&course_id=${data.course_id}&left=${data.left}`;
      } else if (data.taskDone >= 0) {
        url = `get-class-assignment-list?teacher_id=${data.teacher_id}&course_id=${data.course_id}&were_carried_out=${data.taskDone}`;
      }
      const res = await axiosClient.get(url);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const GetCourseWiseMeeting = createAsyncThunk(
  "TeacherSlice/courseWiseMeeting",
  async (data: { course_id: string }) => {
    try {
      const res = await axiosClient.get(
        `get-course-wise-meeting-list?course_id=${data.course_id}`
      );
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const GetAtomicContainerCourseWise = createAsyncThunk(
  "TeacherSlice/courseWiseAtomicContainer",
  async (data: { course_id: string }) => {
    try {
      const res = await axiosClient.get(
        `get-atomic-container-course-wise?course_id=${data.course_id}`
      );
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const GetTeacherProfileData = createAsyncThunk(
  "TeacherSlice/teacherProfileData",
  async (data: { teacher_id: string | null }) => {
    try {
      const res = await axiosClient.get(
        `get-teacher-data?teacher_id=${data.teacher_id}`
      );
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

interface initialStage {
  studentData: any[];
  isLoading: boolean;
  error: any;
  studentDashboardData: any[];
  courseWise: any[];
  switchAssignmentStatus: any;
  switchAssignmentStudent: any;
  studentLevelData: any;
  changeStatusAtomicContainersData: any;
  submitAssignmentStudent: any;
  studentOnBoardingInfo:onboardingInfo[];
  classAssignmentList: any;
  saveStudentLevelData: any[];
  courseWiseAtomicContainer: any[];
  atomic_ContainerData_teacherSide: any[];
  teacherProfileData: {
    [key: string]: any;
  };
}

const initialState: initialStage = {
  studentData: [],
  isLoading: false,
  error: null,
  studentDashboardData: [],
  switchAssignmentStatus: [],
  switchAssignmentStudent: [],
  studentOnBoardingInfo: [],
  changeStatusAtomicContainersData: [],
  courseWise: [],
  studentLevelData: [],
  submitAssignmentStudent: [],
  classAssignmentList: [],
  atomic_ContainerData_teacherSide: [],
  saveStudentLevelData: [],
  courseWiseAtomicContainer: [],
  teacherProfileData: {},
};

const TeacherSlice = createSlice({
  name: "TeacherSlice",
  initialState,
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(FetchStudentsBasedOnCourse.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(FetchStudentsBasedOnCourse.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          studentData: action.payload.data?.students,
        };
      })
      .addCase(FetchStudentsBasedOnCourse.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(GetCourseWiseMeeting.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(GetCourseWiseMeeting.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          courseWise: action.payload.data,
        };
      })
      .addCase(GetCourseWiseMeeting.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(GetAtomicContainerCourseWise.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(GetAtomicContainerCourseWise.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          courseWiseAtomicContainer: action.payload.data,
        };
      })
      .addCase(GetAtomicContainerCourseWise.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(FetchStudentsDashboard.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
          studentDashboardData:[]
        };
      })
      .addCase(FetchStudentsDashboard.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          studentDashboardData: action.payload.data,
        };
      })
      .addCase(FetchStudentsDashboard.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(ChangeStudentAssignmentStatus.pending, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(ChangeStudentAssignmentStatus.fulfilled, (state, action) => {
        return {
          ...state,
          switchAssignmentStatus: action.payload,
          isLoading: true,
        };
      })
      .addCase(ChangeStudentAssignmentStatus.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(SwitchAssignmentStudent.pending, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(SwitchAssignmentStudent.fulfilled, (state, action) => {
        return {
          ...state,
          switchAssignmentStudent: action.payload,
          isLoading: true,
        };
      })
      .addCase(SwitchAssignmentStudent.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })

      .addCase(GetStudentLevelData.pending, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(GetStudentLevelData.fulfilled, (state, action) => {
        return {
          ...state,
          studentLevelData: action.payload.data,
          studentOnBoardingInfo: action.payload.onboarding_info,
          isLoading: false,
        };
      })
      .addCase(GetStudentLevelData.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(SaveAssignment.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(SaveAssignment.fulfilled, (state, action) => {
        return {
          ...state,
          submitAssignmentStudent: action.payload.data,
          isLoading: false,
        };
      })
      .addCase(SaveAssignment.rejected, (state, action) => {
        return {
          ...state,
          error: action.error.message,
          isLoading: false,
        };
      })
      .addCase(GetClassAssignmentList.pending, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(GetClassAssignmentList.fulfilled, (state, action) => {
        return {
          ...state,
          classAssignmentList: action.payload.data.data,
          isLoading: true,
        };
      })
      .addCase(GetClassAssignmentList.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(SaveStudentLevelData.pending, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(SaveStudentLevelData.fulfilled, (state, action) => {
        return {
          ...state,
          saveStudentLevelData: action.payload.data,
          isLoading: true,
        };
      })
      .addCase(SaveStudentLevelData.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(ChangeStatusAtomicContainer.pending, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(ChangeStatusAtomicContainer.fulfilled, (state, action) => {
        return {
          ...state,
          changeStatusAtomicContainersData: action.payload.data,
          isLoading: true,
        };
      })
      .addCase(ChangeStatusAtomicContainer.rejected, (state, action) => {
        return {
          ...state,
          ...state,
          error: action.error.message,
          isLoading: false,
        };
      })
      .addCase(FetchAtomicContainerDataTeacherSide.pending, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(
        FetchAtomicContainerDataTeacherSide.fulfilled,
        (state, action) => {
          return {
            ...state,
            atomic_ContainerData_teacherSide: action.payload.data,
            isLoading: true,
          };
        }
      )
      .addCase(
        FetchAtomicContainerDataTeacherSide.rejected,
        (state, action) => {
          return {
            ...state,
            error: action.payload,
            isLoading: false,
          };
        }
      )
      .addCase(GetTeacherProfileData.pending, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(GetTeacherProfileData.fulfilled, (state, action) => {
        return {
          ...state,
          teacherProfileData: action.payload.data,
          isLoading: true,
        };
      })
      .addCase(GetTeacherProfileData.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      });
  },
});

export default TeacherSlice.reducer;
