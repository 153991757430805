import { useAppDispatch, useAppSelector } from "Redux/App/hooks";
import AtomicVideo from "views/Questions/AtomicVideo";
import AtomicDocument from "views/Questions/AtomicDocument";
import AtomicGuide from "views/Questions/AtomicGuide";
import AtomicLinkExternal from "views/Questions/AtomicLinkExternal";
import AtomicOnlineVideo from "views/Questions/AtomicOnlineVideo";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";
import {
  CompleteContainerAtomic,
  FetchAtomicAndContainerData,
  GetNextAssignment,
  setAssignmentData,
  setIconSidebar,
  setOpenAssignmentSuccess,
  setOpenSessionSuccess,
  setSideBar,
  setActiveIndex,
} from "Redux/feature/StudentHome";
import {
  FetchQuestions,
  GetAllSubmittedQuestion,
  setAction,
  setNumericBar,
  setPreviewName,
  setQuestionBar,
  setQuizQuestionComplete,
  setResetAss,
  setShowResult,
  setShowResultMode,
  setFetchAllSubmittedQuestions,
  updateActiveQuestion,
  setProgressBarFlag,
  setQuizColorChange,
  setResearchTab,
} from "Redux/feature/Questions";
import { ResetQuestionsState } from "Redux/feature/Questions";
import AssignmentDialog from "views/common/AssignmentDialog";
import AtomicLinkSystem from "views/Questions/AtomicLinkSystem";
import { useWiris } from "hooks/useWiris";
import SessionDialog from "views/common/SessionDialog";
import DigitalBook from "views/Questions/Digitalbook";
import {
  setPrincipleAccordionNavigationFlag,
  setResultPage,
} from "Redux/feature/ChapterExamSlice";
import { getCookie } from "views/common/cookieUtils";

type MyComponentProps = {};

const QuestionPages: React.FC<MyComponentProps> = () => {
  const [searchParams, setSerchParams] = useSearchParams();
  const assignmentData: any = localStorage?.getItem("assignment");
  const assignment: any = JSON?.parse(assignmentData);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const atomicIndex = useAppSelector(
    (state) => state?.StudentsHomeData?.activeAtomicIndex
  );

  const assignmentModal: boolean = useAppSelector(
    (state) => state?.StudentsHomeData?.openAssignmentSuccess
  );
  const sessionModal: boolean = useAppSelector(
    (state) => state?.StudentsHomeData?.openSessionSuccess
  );
  const isLoading: boolean = useAppSelector(
    (state) => state?.StudentsHomeData?.isLoading
  );

  useWiris(isLoading);

  let atomicUnitData: any = useAppSelector(
    (state) => state?.StudentsHomeData?.atomicUnitsData
  );
  const selectedCourse: any = useAppSelector(
    (state) => state?.BoardExam?.selectedCourse
  );

  const location = useLocation();
  const propsData: any = location?.state;
  const atomicLibrary = searchParams?.get("atomicLibrary");
  const atomic_type = searchParams?.get("atomic_type");
  const product_exam = searchParams.get("product_exam");
  const assignmentDetail = JSON.parse(localStorage.getItem("assignment") || "[]")
  const user_id = getCookie("id");
  const defaultCourseId = getCookie("default_course_id");

  const resetAss = useAppSelector((state) => state?.Questions?.resetAss);
  const previousElement = useAppSelector(
    (state) => state?.Questions?.previousElement
  );

  const assignment_id = searchParams?.get("assignment_id");

  const handleAssignmentData = () => {
    let arr: any = [];
    let prevContainerTypeId: number | null = null;
    if(searchParams.get("product_exam")){
      if (assignmentDetail?.length > 0) {
        assignmentDetail?.forEach((data: any) => {
          
            if (
              data.types === "container" &&
              data.container[0]?.container_type_id == 5
            ) {
              if (prevContainerTypeId != 5) {
                arr.push(data);
                prevContainerTypeId = 5;
              }
            } else {
              arr.push(data);
              prevContainerTypeId = null;
            }
        });
      }
    }else{
      atomicUnitData?.forEach((atomicData: any) => {
        if (atomicData?.meeting?.assignment?.length > 0) {
          atomicData.meeting?.assignment?.forEach((item: any) => {
            if (item.atomics_containers?.length > 0) {
              item.atomics_containers?.forEach((data: any) => {
                if (data?.assignment_id == assignment_id) {
                  const matchingObject = atomicData?.meeting?.assignment.find(
                    (test: any) => test.id == assignment_id
                  );
                  const assignment_name = matchingObject.preview_name;
                  if (matchingObject?.progress == 100) {
                    dispatch(setIconSidebar(matchingObject?.icon?.icon1));
                  } else {
                    dispatch(setIconSidebar(matchingObject?.icon?.icon2));
                  }
                  dispatch(setPreviewName(assignment_name));
                  if (
                    data.types === "container" &&
                    data.container[0]?.container_type_id == 5
                  ) {
                    if (prevContainerTypeId != 5) {
                      arr.push(data);
                      prevContainerTypeId = 5;
                    }
                  } else {
                    arr.push(data);
                    prevContainerTypeId = null;
                  }
                }
              });
            }
          });
        }
      });
    }

    

    dispatch(setSideBar(arr));
  };
  const nextAssignment = () => {
    const meetingIndex = atomicUnitData?.findIndex((item: any) => {
      return item?.meeting?.assignment?.some(
        (assignmentData: any) => assignmentData?.id == assignment_id
      );
    });
    if (user_id) {
      dispatch(
        GetNextAssignment({
          course_id: defaultCourseId ? defaultCourseId : selectedCourse,
          meeting_id: localStorage.getItem("meetingId")
            ? localStorage.getItem("meetingId")
            : atomicUnitData[meetingIndex]?.meeting?.id,
          assignment_id: assignment_id,
          student_id: user_id,
        })
      )?.then((resp: any) => {
        if (resp?.payload?.data?.length == 0) {
          if (resp?.payload?.meeting_completed == 1) {
            dispatch(setOpenSessionSuccess(true));
          }
          navigate("/student-home");
        } else {
          if (resp?.payload?.meeting_completed == 1) {
            dispatch(setOpenSessionSuccess(true));
          } else {
            dispatch(setOpenAssignmentSuccess(true));
          }
          var jsonString = JSON?.stringify(
            resp?.payload?.data?.atomics_containers
          );
          localStorage?.setItem("assignment", jsonString);
          const meetingData = JSON.stringify(
            resp.payload.data.meeting_assignment.meeting_id
          );
          localStorage.setItem("meetingId", meetingData);

          dispatch(setAssignmentData(resp?.payload?.data?.atomics_containers));

          const indexFirstNotCompleted =
            resp?.payload?.data?.atomics_containers?.findIndex(
              (item: { is_completed: 0 }) => item?.is_completed == 0
            );
          dispatch(setActiveIndex(indexFirstNotCompleted));

          const firstNotCompletedItem =
            resp?.payload?.data?.atomics_containers[indexFirstNotCompleted];
          if (firstNotCompletedItem?.types == "container") {
            const examTime =
              firstNotCompletedItem?.container[0]?.container_duration;
              let containerUrl
              if(firstNotCompletedItem?.container[0]?.container_type_id == 10 ||
                firstNotCompletedItem?.container[0]?.container_type_id == 11){
                  containerUrl = `/question-pages?container_type_id=${firstNotCompletedItem?.container[0]?.container_type_id}&container_id=${firstNotCompletedItem?.atomic_container_id}&assignment_id=${firstNotCompletedItem?.assignment_id}&&chapter_type=${firstNotCompletedItem?.container[0]?.chapter_type}`;

                }else{
                   containerUrl = `/question-pages?container_type_id=${firstNotCompletedItem?.container[0]?.container_type_id}&container_id=${firstNotCompletedItem?.atomic_container_id}&assignment_id=${firstNotCompletedItem?.assignment_id}`;

                }
            const state = { examTime };
            navigate(containerUrl, { state });
          } else {
            const atomicType = firstNotCompletedItem?.atomic[0]?.types?.name;
            const assignmentId = firstNotCompletedItem?.assignment_id;
            const atomic_id = firstNotCompletedItem?.atomic_container_id;
            const link = firstNotCompletedItem?.atomic[0]?.link;

            const url = `/question-pages?atomic_type=${atomicType}&atomic_id=${atomic_id}&assignment_id=${assignmentId}&atomicLibrary=${true}`;

            const state = { link };
            navigate(url, { state });
          }
        }
      });
    }
  };

  const handleIncrementIndex = (
    studentId: string | undefined,
    assignmentId: string,
    type: string,
    id: string
  ) => {
    const meetingIndex = atomicUnitData?.findIndex((item: any) => {
      return item?.meeting?.assignment?.some(
        (assignmentData: any) => assignmentData?.id == assignment_id
      );
    });
    dispatch(
      CompleteContainerAtomic({
        student_id: studentId ? studentId : null,
        assignment_id: assignmentId,
        meeting_id: localStorage.getItem("meetingId")
          ? localStorage.getItem("meetingId")
          : atomicUnitData[meetingIndex]?.meeting?.id,
        course_id: defaultCourseId ? defaultCourseId : selectedCourse,
        type: "atomic",
        id: id,
      })
    )?.then((res: any) => {
      if (res?.payload?.code == 200 && user_id) {
        dispatch(
          FetchAtomicAndContainerData({
            id: user_id,
            course_id: defaultCourseId ? defaultCourseId : selectedCourse,
          })
        )?.then((resp: any) => {
          if (resp) {
            atomicUnitData = resp?.payload?.data;
            handleAssignmentData();
          }
        });
        const atomicType = searchParams?.get("atomic_type");
        const atomic_id = searchParams?.get("atomic_id");

        const currentIndex = assignment?.findIndex((item: any) => {
          return (
            item?.types === "atomic" &&
            item?.atomic[0]?.types?.name === atomicType &&
            item?.atomic_container_id == atomic_id
          );
        });
        dispatch(setActiveIndex(currentIndex));
        if (assignment?.length - 1 == atomicIndex) {
          dispatch(ResetQuestionsState());
          dispatch(setActiveIndex(0));
          dispatch(setSideBar([]));
          if (resetAss) {
            navigate("/student-home");
            dispatch(setResetAss(false));
          } else {
            nextAssignment();
          }
        } else {
          if (currentIndex >= 0 && currentIndex < assignment?.length - 1) {
            const nextElement = assignment[currentIndex + 1];
            if (nextElement?.types == "container") {
              dispatch(setQuizQuestionComplete(false));
              dispatch(setActiveIndex(currentIndex + 1));
              dispatch(setFetchAllSubmittedQuestions([]));
              dispatch(updateActiveQuestion(0));
              if (nextElement?.container[0]?.container_type_id == "1") {
                dispatch(setQuestionBar(false));
              }

              dispatch(setQuizQuestionComplete(false));
              if (resetAss || previousElement) {
                if (
                  (resetAss || previousElement) &&
                  (nextElement?.container[0]?.container_type_id == 4 ||
                    nextElement?.container[0]?.container_type_id == 5)
                ) {
                  if (nextElement?.container[0]?.container_type_id == 4) {
                    dispatch(
                      GetAllSubmittedQuestion({
                        user_id: user_id,
                        container_id: nextElement?.atomic_container_id,
                      })
                    );
                  }else if(nextElement?.container[0]?.container_type_id == 10||nextElement?.container[0]?.container_type_id == 11){
                    const lastIndex = assignment.length - 1;
                    const lastElement = assignment[lastIndex]
                dispatch(setActiveIndex(lastIndex));
                    navigate(
                      `/question-pages?container_type_id=${lastElement?.container[0]?.container_type_id}&container_id=${lastElement?.atomic_container_id}&assignment_id=${lastElement?.assignment_id}&chapter_type=${lastElement?.container[0]?.chapter_type}`
                    );
                  } else {
                    dispatch(setResultPage(true));
                    dispatch(setPrincipleAccordionNavigationFlag(true));
                    dispatch(setProgressBarFlag(false));
                    dispatch(setNumericBar(false));
                    dispatch(setQuizQuestionComplete(false));
                    dispatch(setQuizColorChange(false));
                    dispatch(updateActiveQuestion(0));
                    dispatch(setQuestionBar(false));

                    dispatch(
                      GetAllSubmittedQuestion({
                        user_id: user_id,
                        assignment_id: nextElement?.assignment_id,
                      })
                    );
                  }
                  if(product_exam){
                    navigate(
                      `/question-pages?container_type_id=${nextElement?.container[0]?.container_type_id}&container_id=${nextElement?.atomic_container_id}&assignment_id=${nextElement?.assignment_id}&product_exam=${true}`
                    );
                  }else{
                    navigate(
                      `/question-pages?container_type_id=${nextElement?.container[0]?.container_type_id}&container_id=${nextElement?.atomic_container_id}&assignment_id=${nextElement?.assignment_id}`
                    ); 
                  }

                
                  dispatch(setAction(true));
                  dispatch(setShowResult(true));
                  dispatch(setShowResultMode(true));
                  dispatch(setNumericBar(true));
                  dispatch(setQuizQuestionComplete(true));
                } else {
                  if(nextElement?.container[0]?.container_type_id=="10"||nextElement?.container[0]?.container_type_id=="11"){
                    navigate(
                      `/question-pages?container_type_id=${nextElement?.container[0]?.container_type_id}&container_id=${nextElement?.atomic_container_id}&assignment_id=${nextElement?.assignment_id}&chapter_type=${nextElement?.container[0]?.chapter_type}`
                    );
                  }else{
                  navigate(
                    `/practice-summary?container_type_id=${nextElement?.container[0]?.container_type_id}&container_id=${nextElement?.atomic_container_id}&assignment_id=${nextElement?.assignment_id}`
                  );
                }
                }
                dispatch(
                  FetchQuestions({
                    user_id: user_id,
                    container_id: nextElement?.atomic_container_id,
                  })
                );
              } else {
                const examTime = nextElement?.container[0]?.container_duration;
                let  containerUrl
                if(product_exam){
                 
                 containerUrl = `/question-pages?container_type_id=${nextElement?.container[0]?.container_type_id}&container_id=${nextElement?.atomic_container_id}&assignment_id=${nextElement?.assignment_id}&product_exam=${true}`;

                }else if(nextElement?.container[0]?.container_type_id=="10"||nextElement?.container[0]?.container_type_id=="11"){
                  containerUrl = `/question-pages?container_type_id=${nextElement?.container[0]?.container_type_id}&container_id=${nextElement?.atomic_container_id}&assignment_id=${nextElement?.assignment_id}&chapter_type=${nextElement?.container[0]?.chapter_type}`;

                }
                else{
                 containerUrl = `/question-pages?container_type_id=${nextElement?.container[0]?.container_type_id}&container_id=${nextElement?.atomic_container_id}&assignment_id=${nextElement?.assignment_id}`;

                 
                }

                // const containerUrl = `/question-pages?container_type_id=${nextElement?.container[0]?.container_type_id}&container_id=${nextElement?.atomic_container_id}&assignment_id=${nextElement?.assignment_id}`;

                const state = { examTime };
                navigate(containerUrl, { state });
              }
              dispatch(setActiveIndex(currentIndex + 1));
            } else if (nextElement?.types == "atomic") {
              const atomicType = nextElement?.atomic[0]?.types?.name;
              const assignmentId = nextElement?.assignment_id;
              const atomic_id = nextElement?.atomic_container_id;
              const link = nextElement?.atomic[0]?.link;

              const url = `/question-pages?atomic_type=${atomicType}&atomic_id=${atomic_id}&assignment_id=${assignmentId}&atomicLibrary=${true}`;

              const state = { link };
              navigate(url, { state });

              dispatch(setActiveIndex(currentIndex + 1));
            }
          } else {
            navigate("/student-home");
          }
        }
      }
    });
  };

  const handleDecrementIndex = () => {
    const atomicType = searchParams?.get("atomic_type");
    const atomic_id = searchParams?.get("atomic_id");

    const currentIndex = assignment?.findIndex((item: any) => {
      return (
        item?.types === "atomic" &&
        item?.atomic[0]?.types?.name === atomicType &&
        item?.atomic_container_id == atomic_id
      );
    });
    if (currentIndex >= 0 && currentIndex < assignment?.length) {
      const nextElement = assignment[currentIndex - 1];
      if (nextElement?.types == "container") {
        if (nextElement?.container[0]?.container_type_id === 4) {
          dispatch(
            GetAllSubmittedQuestion({
              user_id: user_id,
              container_id: nextElement?.atomic_container_id,
            })
          );
          navigate(
            `/question-pages?container_type_id=${nextElement?.container[0]?.container_type_id}&container_id=${nextElement?.atomic_container_id}&assignment_id=${nextElement?.assignment_id}`
          );
          dispatch(setAction(true));
          dispatch(setShowResult(true));
          dispatch(setShowResultMode(true));
          dispatch(setNumericBar(true));
          dispatch(setQuizQuestionComplete(true));
        } else {
          navigate(
            `/practice-summary?container_type_id=${nextElement?.container[0]?.container_type_id}&container_id=${nextElement?.atomic_container_id}&assignment_id=${nextElement?.assignment_id}`
          );
        }
        dispatch(setActiveIndex(currentIndex - 1));
      } else if (nextElement?.types == "atomic") {
        const atomicType = nextElement?.atomic[0]?.types?.name;
        const assignmentId = nextElement?.assignment_id;
        const atomic_id = nextElement?.atomic_container_id;
        const link = nextElement?.atomic[0]?.link;

        const url = `/question-pages?atomic_type=${atomicType}&atomic_id=${atomic_id}&assignment_id=${assignmentId}&atomicLibrary=${true}`;

        const state = { link };
        navigate(url, { state });
        dispatch(setActiveIndex(currentIndex - 1));
      }
    }
  };

  return (
    <>
      <AssignmentDialog openAssignmentSuccess={assignmentModal} />
      <SessionDialog openSessionSuccess={sessionModal} />
      {atomicLibrary === "false" &&(
        <>
          {(atomic_type=="guides") && (
            <AtomicGuide
              assignmentId={propsData?.atomicType[0]?.assignment_id}
              id={propsData?.atomicType[0]?.id}
              type={propsData?.atomicType[0]?.types?.name}
              handleIncrementIndex={handleIncrementIndex}
              handleDecrementIndex={handleDecrementIndex}
            />
          )}
          {(atomic_type=="video") && (
            <AtomicVideo
              assignmentId={propsData?.atomicType[0]?.assignment_id}
              id={propsData?.atomicType[0]?.id}
              type={propsData?.atomicType[0]?.types?.name}
              handleIncrementIndex={handleIncrementIndex}
              handleDecrementIndex={handleDecrementIndex}
            />
          )}

          {(atomic_type=="files") && (
            <AtomicDocument
              assignmentId={propsData?.atomicType[0]?.assignment_id}
              id={propsData?.atomicType[0]?.id}
              type={propsData?.atomicType[0]?.types?.name}
              handleIncrementIndex={handleIncrementIndex}
              handleDecrementIndex={handleDecrementIndex}
            />
          )}

          {(atomic_type=="external link") && (
            <AtomicLinkExternal
              assignmentId={propsData?.atomicType[0]?.assignment_id}
              id={propsData?.atomicType[0]?.id}
              type={propsData?.atomicType[0]?.types?.name}
              handleIncrementIndex={handleIncrementIndex}
              handleDecrementIndex={handleDecrementIndex}
            />
          )}
          {(atomic_type=="system link") && (
            <AtomicLinkSystem
              assignmentId={propsData?.atomicType[0]?.assignment_id}
              id={propsData?.atomicType[0]?.id}
              type={propsData?.atomicType[0]?.types?.name}
              handleIncrementIndex={handleIncrementIndex}
              handleDecrementIndex={handleDecrementIndex}
            />
          )}
          {(atomic_type=="online video") && (
            <AtomicOnlineVideo
              assignmentId={propsData?.atomicType[0]?.assignment_id}
              id={propsData?.atomicType[0]?.id}
              type={propsData?.atomicType[0]?.types?.name}
              handleIncrementIndex={handleIncrementIndex}
              handleDecrementIndex={handleDecrementIndex}
            />
          )}
          {(atomic_type=="digital book") && (
            <DigitalBook
              assignmentId={propsData?.atomicType[0]?.assignment_id}
              id={propsData?.atomicType[0]?.id}
              type={propsData?.atomicType[0]?.types?.name}
              handleIncrementIndex={handleIncrementIndex}
              handleDecrementIndex={handleDecrementIndex}
            />
          )}
        </>
      )}
      {atomicLibrary === "true" &&
        searchParams?.get("atomic_type") == "guides" && (
          <AtomicGuide
            handleIncrementIndex={handleIncrementIndex}
            handleDecrementIndex={handleDecrementIndex}
          />
        )}
      {atomicLibrary === "true" &&
        searchParams?.get("atomic_type") == "video" && (
          <AtomicVideo
            handleIncrementIndex={handleIncrementIndex}
            handleDecrementIndex={handleDecrementIndex}
          />
        )}{" "}
      {atomicLibrary === "true" &&
        searchParams?.get("atomic_type") == "files" && (
          <AtomicDocument
            handleIncrementIndex={handleIncrementIndex}
            handleDecrementIndex={handleDecrementIndex}
          />
        )}{" "}
      {atomicLibrary === "true" &&
        searchParams?.get("atomic_type") == "external link" && (
          <AtomicLinkExternal
            handleIncrementIndex={handleIncrementIndex}
            handleDecrementIndex={handleDecrementIndex}
          />
        )}
      {atomicLibrary === "true" &&
        searchParams?.get("atomic_type") == "system link" && (
          <AtomicLinkSystem
            handleIncrementIndex={handleIncrementIndex}
            handleDecrementIndex={handleDecrementIndex}
          />
        )}
      {atomicLibrary === "true" &&
        searchParams?.get("atomic_type") == "online video" && (
          <AtomicOnlineVideo
            handleIncrementIndex={handleIncrementIndex}
            handleDecrementIndex={handleDecrementIndex}
          />
        )}
      {atomicLibrary === "true" &&
        searchParams?.get("atomic_type") == "digital book" && (
          <DigitalBook
            handleIncrementIndex={handleIncrementIndex}
            handleDecrementIndex={handleDecrementIndex}
          />
        )}
    </>
  );
};

export default QuestionPages;
